import {observer} from 'mobx-react';
import {format} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import http from 'http.js';

import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';
import Tooltip from 'core/components/Tooltip.js';
import ProcedureGraph from 'professional/components/ProcedureGraph.js';
import ProfessionalHealthFund from 'professional/models/professional_health_fund.js';

import {LinkV2} from '@HealthShareAU/hs-component-library';

export default
@observer
class ProcedureInfo extends React.Component {
    static propTypes = {
        healthFund: PropTypes.object.isRequired,
        healthFundData: PropTypes.instanceOf(ProfessionalHealthFund)
            .isRequired,
        onProcedureFeeMount: PropTypes.func,
        profileId: PropTypes.number.isRequired,
        profileName: PropTypes.string.isRequired,
        version: PropTypes.number,
    };

    static defaultProps = {
        version: 1,
    };

    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.state = {
            membershipNumber: undefined,
            selectedProcedure: undefined,
        };
    }

    componentDidMount() {
        if (this.props.onProcedureFeeMount) {
            this.props.onProcedureFeeMount(this.ref);
        }
    }

    @autobind
    updateSelectedProcedure(event) {
        const procedureId = event.currentTarget.value;
        this.trackHealthFundProcedureSelect(procedureId);
        this.setState({
            selectedProcedure: procedureId,
        });
    }

    trackHealthFundProcedureSelect(procedure) {
        const {healthFund, profileId} = this.props;
        http.post({
            url: '/api/professionals/v1/events/health-fund-procedure-select/',
            data: {
                'health_fund': healthFund?.id,
                practitioner: profileId,
                procedure,
            },
        });
    }
    renderProcedureLink(url) {
        const {version} = this.props;
        const text = 'Learn more about this procedure';
        if (version > 1) {
            return <LinkV2 href={url} text={text} />;
        }

        return (
            <Link href={url} isExternalLink={true}>
                {text}
            </Link>
        );
    }
    renderAfterGraphData(procedure) {
        const {healthFund, profileName} = this.props;
        const procedureFormat = 'do MMMM, yyyy';
        let startDate = null;
        let endDate = null;
        const {
            admissionsEndDate,
            admissionsStartDate,
            bands,
            mbsItems,
            name,
            url,
        } = procedure;
        if (admissionsStartDate) {
            startDate = format(admissionsStartDate, procedureFormat);
        }
        if (admissionsEndDate) {
            endDate = format(admissionsEndDate, procedureFormat);
        }
        const totalAdmissions = bands.reduce(
            (value, band) => value + band.admissions,
            0,
        );
        return (
            <>
                <figcaption>
                    {!!mbsItems.length && (
                        <cite>
                            {'MBS items used: '}
                            {mbsItems.map((item) => item.number).join(', ')}
                        </cite>
                    )}
                    <p>
                        {
                            'The graph shows the out-of-pocket costs experienced by '
                        }
                        <strong>{healthFund.name}</strong>
                        {' members with '}
                        <strong>{profileName}</strong>
                        {' for hospital admissions for '}
                        <strong>{name}</strong>
                        {'.'}
                    </p>
                    <p>
                        {'Data shows '}
                        <strong>{totalAdmissions}</strong>
                        {' procedure'}
                        {totalAdmissions > 1 && 's'}
                        {' performed between '}
                        <strong>{startDate}</strong>
                        {' and '}
                        <strong>{endDate}</strong>
                        {'.'}
                    </p>
                </figcaption>
                {url && this.renderProcedureLink(url)}
            </>
        );
    }

    renderProcedureData(procedure) {
        if (procedure) {
            return (
                <div className="hs-procedure-graph">
                    <ProcedureGraph procedure={procedure} />
                    {this.renderAfterGraphData(procedure)}
                </div>
            );
        } else {
            return null;
        }
    }

    wrapTooltip(tooltipText) {
        return <p>{tooltipText}</p>;
    }

    render() {
        const {
            healthFund: {outOfPocketCostsTooltip},
            healthFundData: {procedures},
        } = this.props;
        if (!procedures?.length) {
            return null;
        }
        const {selectedProcedure} = this.state;
        const procedure = procedures.find(
            (procedure) => procedure.id === parseInt(selectedProcedure),
        );
        return (
            <>
                <form>
                    <h3 ref={this.ref}>{'Procedure out-of-pocket costs'}</h3>
                    {outOfPocketCostsTooltip && (
                        <Tooltip
                            content={this.wrapTooltip(outOfPocketCostsTooltip)}
                            defaultPosition="top"
                            timeOut={500}
                        >
                            <Icon name="info" />
                        </Tooltip>
                    )}
                    <select
                        data-testid="procedure-select"
                        onChange={this.updateSelectedProcedure}
                        value={selectedProcedure || ''}
                    >
                        <option disabled value="">
                            {'Choose your procedure'}
                        </option>
                        {procedures.map((procedure) => (
                            <option key={procedure.id} value={procedure.id}>
                                {procedure.name}
                            </option>
                        ))}
                    </select>
                </form>
                {this.renderProcedureData(procedure)}
            </>
        );
    }
}
